"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_property_decorator_1 = require("vue-property-decorator");
var BaseView_1 = require("@shared/views/BaseView");
var DeckVariationList = /** @class */ (function (_super) {
    __extends(DeckVariationList, _super);
    function DeckVariationList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = false;
        _this.showLength = false;
        _this.showWidth = false;
        _this.showNose = false;
        _this.showTail = false;
        _this.showWheelBase = false;
        _this.showInfo = true;
        _this.empty = true;
        return _this;
    }
    DeckVariationList.prototype.buildList = function () {
        this.empty = true;
        for (var _i = 0, _a = this.variations; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item.noseLength) {
                this.showNose = true;
                this.empty = false;
            }
            if (item.tailLength) {
                this.showTail = true;
                this.empty = false;
            }
            if (item.wheelBase) {
                this.showWheelBase = true;
                this.empty = false;
            }
            if (item.length) {
                this.showLength = true;
                this.empty = false;
            }
            if (item.width) {
                this.showWidth = true;
                this.empty = false;
            }
        }
    };
    __decorate([
        vue_property_decorator_1.Prop({ type: Object })
    ], DeckVariationList.prototype, "model", void 0);
    __decorate([
        vue_property_decorator_1.Prop({ default: function () { return []; } })
    ], DeckVariationList.prototype, "variations", void 0);
    __decorate([
        vue_property_decorator_1.Watch('variations', { immediate: true, deep: true })
    ], DeckVariationList.prototype, "buildList", null);
    DeckVariationList = __decorate([
        vue_property_decorator_1.Component({})
    ], DeckVariationList);
    return DeckVariationList;
}(BaseView_1.BaseView));
exports.default = DeckVariationList;
