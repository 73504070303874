






































import { Component } from 'vue-property-decorator';
import { BaseView } from '@shared/views/BaseView';
import { Deck, DeckVariation, DeckColor } from '@shared/models';
import DeckImageList from './DeckImageList.vue';
import DeckGeneralInfo from './DeckGeneralInfo.vue';
import DeckVariations from './DeckVariations.vue';
import DeckSideInfo from './DeckSideInfo.vue';
import DeckPalette from './DeckPalette.vue';
import OtherDecksInSeries from './OtherDecksInSeries.vue';

@Component({
	components: {
		DeckImageList,
		DeckGeneralInfo,
		DeckVariations,
		DeckSideInfo,
		DeckPalette,
		OtherDecksInSeries,
	},
})
export default class DeckDetails extends BaseView {

	public model: Deck = new Deck();
	public variations: DeckVariation[] = [];
	public palette: DeckColor[] = []; 

	/** The url of this decks image */
	public imageUrl: string = '';

	/** Fires when this view is mounted */
	public async mounted() {
		this.loading = true;
		try {
			const modelId = this.$route.params.id;
			this.model = await this.$api.deckService.get(modelId);
			this.variations = await this.$api.deckVariationService.list(this.model.id);
			this.palette = await this.$api.deckImageService.generatePalette(this.model.id);

			this.imageUrl = `https://lurker.blob.core.windows.net/decks/d_deck_${this.model.id}.jpg`;
			if (!this.model.description) {
				this.model.description = this.generateDescription();
			}
			this.setTitle(`Skate Lurker - ${this.model.name} Deck`);
			// this.$gtm.trackView(`Deck - ${this.model.name}`, this.$route.path);
		} catch (err) {
			if (err != null && err.request != null && err.request.status === 404) {
				this.$router.push({ name: '404', query: { type: 'deck' }});
			} else {
				this.errorMessage = 'An error occurred while loading this deck';
				this.errorHandler.handle(err, this.errorMessage);
			}
		} finally {
			this.loading = false;
		}
	}

	public generateDescription(): string {
		
		let descr = '';
		if (this.model.companies?.length) {
			descr += `${this.model.companies[0].name} `;
		}
		descr += `<strong>${this.model.name}</strong> deck `;
		if (this.model.year) {
			if (this.model.isApproxYear) {
				descr += `from around ${this.model.year}`;
			} else {
				descr += `from ${this.model.year}`;
			}
		}
		descr += '. ';
		if (this.model.pros?.length) {
			descr += `<em>${this.model.pros[0].displayName}</em> pro model. `;
		}

		if (this.model.artists?.length) {
			descr += `Designed by <em>${this.model.artists[0].displayName}.`;
		}

		return descr;
	}

}

